.login-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.left-image {
  position: absolute;
  top: 0;
  left: 0;
}

.right-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.login-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-title {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
}
.sub-title {
  color: #666666;
  font-size: 16;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.login-label {
  color: #374151;
  font-size: 14;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  width: 20px;
}

.field-login {
  width: 280px;
  padding: 10px;
  border-radius: 9px;
  border: 1px solid #e5e7eb;
}

.remember-me-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.remember-me {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  gap: 15px;
}

#rememberMe {
}

.forget-password-link {
  font-size: 14px;
  color: #666;
  text-decoration: none;
}

.forget-password-link:hover {
  text-decoration: underline;
}

.button-login {
  margin-top: 20px;
  background: #7c3aed;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.continue-container {
  width: 280px;
  padding: 10px;
}
.continue-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  border: 1px solid #cfcfcf;
  width: 100%;
  height: 0;
  flex: 1;
}

.continue-text {
  font-size: 14px;
  color: #6b7280;
  text-align: center;
  margin: 0 5px;
}

.continue-options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 10px;
}

.continue-option {
  border: 1px solid #e5e7eb;
  color: #000;
  font-size: 14px;
  flex: 1;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.no-account {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.no-account-text {
  color: #4b5563;
  font-size: 14px;
  margin: 0;
}

.no-account-link {
  color: #4280ef;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}


@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .right-image {
    display: none;
  }
  .left-image {
    display: none;
  }
}