.register-page {
  display: flex;
}

.register-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-side {
  flex: 1;
  position: relative;
}
.register-image {
    height: 100%;
    width: 100%;
}

.right-side-description {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 50px;
}

.right-side-description .title {
    font-size: 40px;
    font-weight: bold;
}

.right-side-description .description {
    font-size: 16px;
    font-weight: 100;
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .right-side {
    display: none;
  }
}